html, body {
    font-family: 'Poppins', sans-serif !important;
    line-height: 1;
    letter-spacing: -0.5px;
}

main {
    height: 100% !important;
}

#church_name_column {
    min-width: 250px !important;
}

#church_name {
    background-image: none !important;
}

#nav_title {
    color: white;
    font-weight: 500;
    /*margin-top: 3px;*/
    font-size: 21px;
    padding: 0;
}

#dash_divider {
    border: 0.5px solid #3f51b56b;
    width: 100%;
    margin-top: 0;
    margin-bottom: 25px;
}

.MuiAppBar-colorPrimary {
    background-color: #4169E1 !important;
}

#form_container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    padding-bottom: 0;
    max-width: 700px;
    margin: 0 auto;
}

.step_text {
    margin-bottom: 15px;
    text-align: center;
}

.service_time_text {
    margin-right: 10px;
}

#time1 {
    flex-grow: 1;
}

#form_container form {
    width: 100%;
}

.form_inner {
    display: flex;
    flex-direction: column;
}

.approve_btn {
    background-color: #4169e1;
    border-radius: 3px;
    color: white;
    padding: 5px 20px;
}

.unapprove_btn {
    background-color: #69e141;
    border-radius: 3px;
    font-weight: 500;
    color: #263238;
    padding: 5px 7px;
}

#thankyou_container {
    height: 75vh;
    width: 100%;
}

#thankyou_container p {
    font-size: 15px;
    margin-top: 5px;
    max-width: 600px;
    text-align: justify;
}

#thankyou_container h3 {
    font-weight: 600;
}

#spinner_container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.form_card {
    padding: 20px;
    width: 100%;
    overflow: visible !important;
    display: flex;
    flex-direction: column;
}

.form_card input {
    border-color: hsl(0,0%,80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    padding: 5px;
}

.css-6q0nyr-Svg {
    color: #4169e1 !important;
}

.form_button {
    background-color: #4169e1 !important;
    color: white !important;
    font-weight: 600 !important;
}

.rdg-root {
    height: 100% !important;
}

.rdg-viewport {
    height: 400px !important;
}

.rdg-grid {
    padding-bottom: 0 !important;
}

.sort_icon {
    height: 0.75em !important;
    width: 0.75em !important;
}

.table_header_inner {
    display: inline-block;
}

.pagination_upper {
    margin-bottom: 10px;
}

#up_icon {
    height: auto;
    width: 1.2em;
    margin-left: 0.5px;
    color: white !important;
}

.MuiCardHeader-subheader {
    font-size: 14px !important;
    text-align: left !important;
    font-weight: 600 !important;
    color: #4169E1 !important;
}

.scroll_to_top:hover {
    cursor: pointer;
    opacity: 0.95;
}

.scroll_to_top {
    position: fixed;
    bottom: 2em;
    right: 2em;
    height: 3em;
    width: 3em;
    border-radius: 50%;
    background-color: #4169E1;
    z-index: 9999;
    box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15);
}

.email_btn {
    border-radius: 4px !important;
    border: 0.5px solid #e3e3e300 !important;
    background-color: #e2e0f3 !important;
    box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15) !important;
    padding: 0 15px 0 15px !important;
    text-align: center;
    color: #263238 !important;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    margin-left: 7px;
    /*margin-bottom:15px;*/
    font-size: 12px;
    height: 35px !important;
    font-weight: 500 !important;
}

.email_signup {
    border-radius: 4px !important;
    border: none !important;
    box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15) !important;
    padding: 5px 15px 5px 5px !important;
    font-size: 12px;
    height: 35px !important;
    width: 200px;
}

#email_label {
    font-size: 12px;
    margin-bottom: 5px;
}

.email_btn:focus, .email_btn:focus{
    outline: none;
}

.email_signup:focus, .email_signup:focus{
    outline: none;
}

.email_signup > .MuiFilledInput-root:hover {
    background-color: white;
}

.email_and_form {
    width: 100% !important;
    margin-bottom: 10px;
}

#heart {
    width: 0.5em;
    height: 0.5em;
    margin-right: 5px;
}

#dove_icon_mobile {
    margin:35px;
    height: 5em;
    width: 5em;
    animation: cardDrop 0.7s ease forwards;
}

@keyframes cardDrop {
    0%{ transform: translateY(75px); opacity: 0 }
    85%{ transform: translateY(0) }
    100%{ transform: translateY(5px); opacity: 1 }
}

@keyframes leftIn {
    0%{ transform: translateX(-150px); opacity: 0 }
    85%{ transform: translateX(0) }
    100%{ transform: translateX(5px); opacity: 1 }
}

@keyframes rightIn {
    0%{ transform: translateX(150px); opacity: 0 }
    85%{ transform: translateX(0) }
    100%{ transform: translateX(5px); opacity: 1 }
}


.fade-in {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    animation-duration: 0.5s;
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.MuiTypography-caption {
    color: #546e7a;
    font-size: 17px !important;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 400;
    line-height: 13px;
    display: flex;
    align-items: center;
}

#dove_icon {
    color: white;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    margin-right: 0.4em;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
}

#search_by_state {
    width: 250px;
}

.MuiFormLabel-root.Mui-focused {
    color: #4169E1 !important;
}

#primary {
    color: #4169E1 !important;
    font-weight: 600;
    margin: 0 5px;
}

#email {
    color: #4169E1 !important;
    font-weight: 400;
    margin: 0 5px;
    /*text-decoration: underline;*/
    font-size: 16px;
}

.mobile a {
    width: 55% !important;
}

#footer_container {
    /*border-top: 1px solid #4169E1;*/
    margin-top:25px;
    padding: 25px 0 25px 0;
}

#primary_span {
    color: #4169E1 !important;
    font-weight: 500;
}

.MuiTableCell-head {
    /*text-align: center !important;*/
}

#table_placeholder {
    text-align: center;
    display: table-row-group;
}

#wide_column {
    min-width: 200px !important;
}

#semiwide-column {
    min-width: 100px !important;
}

.timezone_column {
    min-width: 130px !important;
}

/*#smallwide-column {*/
/*min-width: 90px !important;*/
/*}*/

#add_here {
    font-weight: 500;
    font-size: 13px !important;
}

#add_here a {
    font-weight: 500;
    color: white !important;
    padding: 5px !important;
    border: 1px solid white !important;
    border-radius: 4px;
    margin-left: 5px;
}

#back_to_search {
    font-weight: 500;
    font-size: 13px !important;
}

#back_to_search a {
    font-weight: 500;
    color: #4169E1 !important;
    padding: 5px 70px !important;
    border: 1px solid #4169E1 !important;
    border-radius: 4px;
    margin-left: 5px;
}

.MuiOutlinedInput-notchedOutline {
    border: 2px solid #4169E1 !important;
}

.MuiInputLabel-outlined {
    font-weight: 500 !important;
    font-size: 1em !important;
    font-family: 'Poppins', sans-serif !important;
    letter-spacing: -0.5px !important;
}

.makeStyles-quoteText-52 {
    font-weight: 700 !important;
    /*text-shadow: 0px 1px 5px rgba(20,9,5,0.45) !important;*/
    text-shadow: 0px 1px 9px rgba(20,9,5,0.53) !important;
    /*text-transform: uppercase !important;*/
    font-size: 45px !important;
}

#home_text_h1 {
    line-height: 1.2 !important;
    /*text-align: left;*/
    margin-left: 35px;
    font-weight: 600;
    font-size: 55px !important;
    text-shadow: 0px 1px 9px rgba(20,9,5,0.53) !important;
}

#zipcode_card {
    width: 75% !important;
}

#or {
    margin: 0.9em;
    font-size: 30px;
    font-weight: 400;
    text-transform: uppercase;
    border-top: 3px solid #4169E1;
    border-bottom: 3px solid #4169E1;
    min-width: 300px;
}

#zip_card, #date_picker {
    width: 65% !important;
    padding: 10px 35px;
    overflow: visible;
}


.wys_text {
    font-weight: 600;
    color: #4169E1;
}

.MuiPickersToolbar-toolbar, .MuiPickersDay-daySelected, .MuiPickersDay-current, .MuiPickersClockPointer-pointer,
.MuiPickersClock-pin{
    background-color: #4169E1 !important;
}

#find_masses {
    margin-top: 2em;
    width: 50% !important;
    font-weight: 600;
    background-color: #4169E1 !important;
    height: 40px;
    font-size: 17px;
    animation: rightIn 0.7s ease forwards;
}

.MuiTypography-body1 {
    /*font-size: 21px !important;*/
    /*font-weight: 400 !important;*/
    width: 100% !important;
    /*text-align: right;*/
}

.MuiCard-root {
    margin-bottom: 15px;
}

.MuiFormControlLabel-root {
    /*background: white;*/
    width: 100%;
    padding: 4px 50px 4px 41px;
    margin: 0 !important;
}

.PrivateSwitchBase-root-217 {
    padding: 3px !important;
}

.MuiSvgIcon-root {
    font-size: 2rem !important;
}

.MuiSvgIcon-root path {
    color: #4169E1;
}

button .MuiSvgIcon-root {
    font-size: 1.5em !important;
}

.MuiCardContent-root {
    padding-top: 28px !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.MuiFormControl-marginNormal {
    width: 100% !important;
}

#home_content_container a {
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

#home_content_container a:hover {
    text-decoration: none !important;
}

#day_picker_mini {
    justify-content: start !important;
    /*margin: 0 auto !important;*/
    width: 100% !important;
    /*margin: 0 20px !important;*/
}

#day_picker_mini .MuiFormControlLabel-root {
    padding: 0 !important;
}

#day_picker_mini .MuiSvgIcon-root {
    font-size: 1.5rem !important;
}

#day_picker_mini .MuiTypography-body1 {
    line-height: 0 !important;
    font-size: 18px !important;
}

#day_picker_mini .MuiFormGroup-root {
    width: 100% !important;
    margin: 0 20px !important;
}

#day_picker_mini .MuiCard-root {
    margin: 3px;
    padding: 0 15px 0 5px;
}

.MuiFormGroup-row {
    flex-direction: row;
    width: 100% !important;
    /*justify-content: center !important;*/
}

.MuiFormControl-marginNormal {
    width: 100% !important;
}

#church_table {
    width: 100% !important;
}

.MuiGrid-spacing-xs-4 > .MuiGrid-item {
    width: 100% !important;
}

#time_box {
    border: 1px solid black;
    width: 75%;
    margin: 0 auto;
    border-radius: 3px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    letter-spacing: 3px;
    font-weight: 500 !important;
    font-family: Roboto, sans-serif  !important;
}

.MuiPaginationItem-root {
    height: 27px !important;
    min-width: 27px !important;
}

#say_hi {
    color: #546e7a;
    /*font-size: 13px;*/
    font-weight: 400;
    line-height: 13px;
    margin-top: 5px;
}

.react-time-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: 2px solid gray;
    text-align: center;
    /*letter-spacing: 0;*/
    border-radius: 5px;
    font-family: Montserrat, 'Segoe UI', Tahoma, sans-serif !important;
    font-size: 1.25em !important;
    font-weight: 500 !important;
}

#time_container {
    width: 100%;
    text-align: center;
}

.react-timekeeper__minute-select {
    color: #4169E1;
}

.react-time-picker {
    font-family: Roboto sans-serif !important;
    font-size: 1.25em !important;
}

.react-timekeeper {
    background: transparent !important;
    border-radius: 10px !important;
    display: inline-block;
    box-shadow: none !important;
    width: 260px;
    position: relative;
    user-select: none;
    border: 3.5px solid #F4F6F8 !important;
}

.react-timekeeper div {
    background-color: transparent;
    /*border: 2px solid #4169E1;*/
}

.react-timekeeper__clock-wrapper {
    background: #F4F6F8 !important;
    /*border: 3.5px solid #4169E1 !important;*/
    border: 3.5px solid #F4F6F8 !important;
    border-radius: 10px !important;
}

.MuiFormGroup-root {
    width: 50% !important;
}

#date_form {
    width: 65% !important;
}

.check_active {
    background-color: #4169E1 !important;
}

.check_active .MuiTypography-body1 {
    font-weight: 600 !important;
    color: white !important;
}

.Mui-checked .MuiSvgIcon-root path {
    color: white !important;
}

.makeStyles-shiftContent-280 {
    padding: 0 !important;
}

#mobile_header {
    font-weight: 600;
    color: #4169E1;
    margin-top: 50px;
    width: 100% !important;
    text-align: center !important;
}

#mobile_header_loading {
    margin: 0 !important;
    font-weight: 600;
    color: #4169E1;
    width: 100% !important;
    text-align: center !important;
}

#streams_found {
    font-size: 11px;
    font-style: italic;
    margin-top: 5px;
    text-align: left;
    margin-left: 5px;
}


.makeStyles-shiftContent-2, div[class^='makeStyles-content-'], div[class*='makeStyles-content-'] {
    /*padding: 35px 30px 35px 30px !important;*/
    height: auto;
    margin: 30px 0 !important;
}

div[class^='makeStyles-shiftContent'], div[class*='makeStyles-shiftContent']{
    padding: 100px 30px 0 30px !important
}

.MuiOutlinedInput-input {
    padding: 13px 12px !important;
}

.MuiInputBase-input {
    font-size: 16px !important;
}

.MuiTextField-root {
    margin-top: 15px;
}

#box {
    height: 300px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
#box>* {
    /*flex: 1 1 0;*/
}

.makeStyles-root-311 {
    display: none !important;
}

.tablecontent {
    padding: 0
}

/*.tableinner {*/
/*min-width: 800*/
/*}*/

.tablestatusContainer {
    display: flex;
    align-items: center;
}

.tablestatus {
    /*marginRight: theme.spacing(1)*/
}

.tableactions {
    justify-content: flex-end;
}

.MuiGrid-grid-lg-12 {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

#church_profile_icon {
    height: 100px;
    width: auto;
    margin-bottom:10px;
}

#church_profile_name {
    font-weight: 600;
    margin-bottom: 0 !important;
    font-size: 30px;
}

#church_profile {
    margin-top:35px;
    letter-spacing: 0 !important;
}

#church_social_list {
    display: flex;
    flex-direction: row;
    list-style: none;
    margin-top: 10px
}

#church_social_list li {
    margin: 0px 7px;
}

#mobile_2 {
    margin: 0 auto;
}

#mobile a {
    width: 55% !important;
}

a {
    color: #4169E1 !important;
}


#find_masses_mobile {
    margin-top: 1em;
    width: 100% !important;
    font-weight: 600;
    background-color: white !important;
    color: #4169E1 !important;
    height: 40px;
    font-size: 14px;
}

.footer_text {
    letter-spacing: -0.5px !important;
    margin-top: 7px !important;
}

.footer_bottom {
    font-size: 12px;
    margin-top: 12px !important;
    letter-spacing: -0.5px !important;
}


@media (min-width: 825px) {
    #home_pic_grid {
        display: block !important;
        flex-grow: 0 !important;
        max-width: 41.666667% !important;
        flex-basis: 41.666667% !important;
    }
}

#search_prompt {
    font-size: 14px;
    margin: 2px 2px 2px 2px;
}

#search_prompt_2 {
    font-size: 14px;
    margin: 15px 2px 4px 4px;
    justify-content: start !important;
    width: 100%;
}

#day_picker_mini .MuiCard-root {
    margin: 4px 8px 4px 0;
}


@media (max-width: 750px) {
    #dashboard_outer {
        padding-top: 0px !important;
        padding-bottom: 5px !important;
        padding-right: 5px !important;
        padding-left: 5px !important;
    }

    #email {
        font-size: 12px;
    }

    #header_grid {
        padding-right: 25px !important;
        padding-left: 25px !important;
        padding-top: 64px !important;
        padding-bottom: 0 !important;
    }

    .footer_text {
        font-size: 12px !important;
        animation: leftIn 0.7s ease forwards;

    }

    #day_picker_mini .MuiSvgIcon-root {
        font-size: 0.7em !important;
    }

    .PrivateSwitchBase-root-168 {
        padding: 0 4.5px 0 0 !important;
    }
    #day_picker_mini .MuiCard-root {
        margin: 4px 8px 4px 0;
        padding: 3px 15px 4px 9px;
        min-width: 102px;
    }

    #day_picker_mini .MuiTypography-body1 {
        font-size: 14px !important;
    }

    .header_dashboard {
        text-align: center;
        margin-top: 50px;
        margin-bottom: 16px;
        font-size: 1.35em;
        padding-bottom: 8px;
        /*border-bottom: 0.5px solid #4169E1;*/
    }
}

@media (max-width: 550px) {
    main {
        height: auto !important;
    }

    #form_container {
        padding-top: 40px;
        align-items: center;
    }

    #thankyou_container p {
        font-size: 12px;
    }

    #thankyou_container h3 {
        margin-top:25px;
    }

    #back_to_search {
        margin: 0 auto;
    }

    #header_grid {
        padding-bottom: 10px !important;
    }

    #sign_up_container {
        margin: 0 auto !important;
    }

    #up_icon {
        height: auto;
        width: 1em;
        margin-left: 0.5px;
        color: white !important;
    }

    .scroll_to_top {
        position: fixed;
        bottom: 1.3em;
        right: 1.3em;
        height: 2.8em;
        width: 2.8em;
    }

    #add_here {
        font-weight: 500;
        font-size: 11px !important;
    }

    #add_here a {
        font-weight: 500;
        color: white !important;
        padding: 5px !important;
        border: 1px solid white !important;
        border-radius: 4px;
        margin-left: 5px;
    }

    #dove_icon {
        color: white;
        width: 0.85em;
        height: 0.85em;
    }

    .header_dashboard {
        margin-top: 40px !important;
    }

    #nav_title {
        font-size: 17px;
    }

    .email_and_form form {
        margin: 0 auto !important;
    }

    .email_signup {
        margin-bottom: 12px;
    }

    #church_profile {
        margin-top: 80px !important;
        margin-bottom: 10px !important;
    }

    #church_profile_name {
        font-size: 17px;
        margin-bottom: 3px !important;
    }

    .church_info {
        font-size: 14px;

    }
}

@media (max-width: 925px) {
    #home_pic_grid  {
        display: none !important;
        flex-grow: 0 !important;
        max-width: 41.666667% !important;
        flex-basis: 41.666667% !important;
    }

    #daypickergrid  {
        flex-grow: 0 !important;
        max-width: 100% !important;
        flex-basis: 100% !important;
    }
}

#daypickergrid {
    margin: 0 auto !important;
}

#dashboard_outer {
    padding-top: 64px ;
    padding-bottom: 64px ;
    padding-right: 25px ;
    padding-left: 25px ;
}

#view_on_comp {
    width: 100% !important;
    text-align: center !important;
}

.disabled_field {
    opacity: .20 !important;
}

#view_on_comp_2 {
    width: 100% !important;
    text-align: center !important;
    font-size: 10px !important;
    margin-top: 12px;
}

#view_on_comp_3 {
    width: 100% !important;
    text-align: center !important;
    font-size: 12px !important;
    margin-top: 15px;
}

/* DASHBOARD ------------*/

.header_dashboard {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 12px;
}

.makeStyles-shiftContent-2 {
    /*padding: 100px 150px 0 150px !important;*/
}

.makeStyles-root-321 {
    display: none !important;
}

#search_state_container {
    margin-top: 13px;
}

#header_grid {
    display: flex;
    justify-content: start;
    align-items: start;
    padding-bottom: 0;
}



.react-timekeeper__clock-svgs circle {
    /*fill: #4169E1 !important;*/
}

.react-time-picker__inputGroup__minute {
    /*width: 44px !important;*/
}

.react-time-picker__inputGroup__hour {
    /*width: 38px !important;*/
    /*letter-spacing: 4px !important;*/
}

